<template>
    <div class="MemberRefund" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                v-model="form"
                :model="form"
                size="small"
                label-width="85px"
                :rules="rules"
            >
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构组名称">
                            <el-select
                                v-model="member.deptGroupCode"
                                @change="handleChangeGroup"
                                filterable
                                :loading="loadingDeptGroupFlag"
                            >
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="group in meta.groups"
                                    :value="group.code"
                                    :key="group.code"
                                    :label="group.name"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="机构名称">
                            <el-select v-model="member.deptCode" @change="handleQueryPayType" filterable>
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="dept in meta.deptCodes"
                                    :label="dept.name"
                                    :key="dept.code"
                                    :value="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input readonly v-model="member.creator" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="创建时间">
                            <el-date-picker
                                v-model="member.createDate"
                                type="date"
                                value-format="yyyy-MM-dd"
                                readonly
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <ef-remark v-model="form.remark" />
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="save" size="small">保存</el-button>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                stripe
                style="width: 100%"
                :data="createTableData"
                size="mini"
                :highlight-current-row="true"
                max-height="520"
            >
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="会员姓名" width="120" prop="name" />
                <el-table-column label="手机号" width="120" prop="mobile" />
                <el-table-column label="会员卡号" width="120" prop="cardNo" />
                <el-table-column label="会员卡状态" width="120" prop="status">
                    <template slot-scope="scope"
                        ><span>{{ scope.row.status | status }}</span></template
                    >
                </el-table-column>
                <el-table-column label="余额" width="160" prop="currentMoney">
                    <template slot-scope="scope">
                        <span>{{ scope.row.currentMoney | money }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="退款金额" width="160">
                    <template slot-scope="scope">
                        <ef-price-input v-model.number="scope.row.refundMoney" :max="member.maxRefundMoney" />
                    </template>
                </el-table-column>
                <el-table-column label="退款方式" width="160" prop="point">
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.payTemplateCode" filterable size="mini">
                            <el-option label="请选择" value="" />
                            <el-option
                                v-for="payTypes in meta.payTypes"
                                :label="payTypes.name"
                                :value="payTypes.code"
                                :key="payTypes.code"
                            />
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column label="是否退卡" width="160" prop="point">
                    <template slot-scope="scope">
                        <el-select
                            v-model="scope.row.isRefundCardFlag"
                            :disabled="!scope.row.cardNo"
                            @change="scope.row.outWorkCost = undefined"
                            size="mini"
                        >
                            <el-option label="否" :value="0" />
                            <el-option label="是" :value="1" />
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column label="退卡工本费" width="160">
                    <template slot-scope="scope">
                        <ef-price-input
                            v-model.number="scope.row.outWorkCost"
                            :max="10000"
                            :disabled="scope.row.isRefundCardFlag != 1"
                        />
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>
<script>
import UrlUtils from '../../../../js/UrlUtils';
import Utils from '../../../../js/Util';
import EfRemark from 'components/EfRemark';
import EfPriceInput from 'components/EfPriceInput';
import MoneyUtils from 'js/MoneyUtils';
import { remarkValidateRule } from 'js/validate/ValidateCommonRule';
export default {
    name: 'MemberRefund',
    components: { EfRemark, EfPriceInput },
    props: ['form'],
    data() {
        return {
            createTableData: [],
            member: {
                createDate: new Date(),
                creator: this.$store.state.session.name,
                remark: '',
                deptGroupCode: '',
                deptCode: '',
                maxRefundMoney: 0,
            },
            meta: {
                groups: [],
                deptCodes: [],
                payTypes: [],
            },
            loadingDeptGroupFlag: true,
            url: {
                queryGroups: '/system/deptGroup/list',
                queryDeptByGroup: '/system/dept/deptTree',
                save: '/member/refund/createExtend',
                deptMemberMoneyDetail: '/member/rechargeAndRefundDetail',
            },
            rules: {
                remark: remarkValidateRule(),
            },
        };
    },
    filters: {
        status(status) {
            if (typeof status == 'undefined') {
                return '';
            }
            if (status == '0') {
                return '待售';
            } else if (status == '1') {
                return '正常';
            } else if (status == '2') {
                return '丢失';
            } else if (status == '3') {
                return '作废';
            } else {
                return '';
            }
        },
    },
    mounted() {
        UrlUtils.DeptGroup(this, (rst) => {
            this.meta.groups = rst || [];
            this.loadingDeptGroupFlag = false;
        });
        this.handleQueryDetail();
    },
    methods: {
        handleChangeGroup() {
            const _this = this;
            _this.member.deptCode = '';
            _this.meta.deptCodes = [];
            _this.initFormData();
            UrlUtils.QueryRemote(
                this,
                this.url.queryDeptByGroup + '?deptGroupCode=' + _this.member.deptGroupCode,
                (rst) => {
                    _this.meta.deptCodes = rst;
                }
            );
        },
        handleQueryPayType() {
            const _this = this;
            _this.initFormData();
            const _params = {
                params: {
                    memberCode: _this.form.code,
                    deptGroupCode: _this.member.deptGroupCode,
                    deptCode: _this.member.deptCode,
                    flag: 1,
                },
            };
            Utils.queryTable(_this, _this.url.deptMemberMoneyDetail, _params, (data) => {
                _this.meta.payTypes = data.data.payTypeParamList;
                _this.createTableData[0].currentMoney = data.data.currentMoney;
                _this.createTableData[0].cardNo = data.data.cardNo || '';
                _this.createTableData[0].status = data.data.status;
                _this.member.maxRefundMoney = MoneyUtils.moneyToYuan(data.data.currentMoney);
            });
        },
        handleQueryDetail() {
            const _this = this;
            const tem = {
                name: _this.form.name,
                mobile: _this.form.mobile,
                cardNo: '',
                status: '',
                payTemplateCode: '',
                isRefundCardFlag: 0,
            };
            _this.createTableData.push(tem);
        },
        initFormData() {
            this.member.maxRefundMoney = 0;
            this.meta.payTypes = [];
            this.createTableData[0].isRefundCardFlag = 0;
            this.createTableData[0].currentMoney = '';
            this.createTableData[0].payTemplateCode = '';
            this.createTableData[0].cardNo = '';
            this.createTableData[0].status = '';
        },
        save() {
            const _this = this;
            const memberVo = _this.createTableData[0];
            if (!_this.member.deptGroupCode) {
                _this.$message.error('请选择机构组');
                return;
            }
            if (!_this.member.deptCode) {
                _this.$message.error('请选择机构');
                return;
            }
            if (!_this.form.remark) {
                _this.$message.error('请添加备注');
                return;
            }
            const disable = typeof memberVo.refundMoney != 'undefined' && memberVo.payTemplateCode != '';
            if (!disable) {
                _this.$message.error('请选择退款方式和退款金额');
                return;
            }
            if (!memberVo.cardNo && memberVo.isRefundCardFlag == 1) {
                _this.$message.error('卡号为空则不可以退卡');
                return;
            }
            if (memberVo.isRefundCardFlag == 0 && memberVo.outWorkCost) {
                _this.$message.error('不退卡不可以填写手工费');
                return;
            }
            const _params = {
                isRefundCardFlag: memberVo.isRefundCardFlag,
                outWorkCost: MoneyUtils.moneyToDb(MoneyUtils.dealEmptyToZero(memberVo.outWorkCost)),
                memberCode: _this.form.code,
                refundMoney: MoneyUtils.moneyToDb(memberVo.refundMoney),
                payTemplateCode: memberVo.payTemplateCode,
                deptGroupCode: _this.member.deptGroupCode,
                deptCode: _this.member.deptCode,
                companyCode: _this.form.companyCode,
                remark: _this.member.remark,
                cardNo: memberVo.cardNo,
            };
            UrlUtils.PostRemote(_this, _this.url.save, _params);
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}
</style>
